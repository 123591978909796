/* ========================================================================
 * DOM-based Routing
 * Based on https://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 *
 * Google CDN, Latest jQuery
 * To use the default WordPress version of jQuery, go to lib/config.php and
 * remove or comment out: add_theme_support('jquery-cdn');
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
$(document).ready(function() { 
    /*function toggleNavbarMethod() {
        if ($(window).width() > 768) {
            $('.navbar .dropdown').on('mouseover', function(){
                $('.dropdown-toggle', this).trigger('click'); 
            }).on('mouseout', function(){
                $('.dropdown-toggle', this).trigger('click').blur();
            });
        }
        else {
            $('.navbar .dropdown').off('mouseover').off('mouseout');
        }
    }

     // toggle navbar hover
        toggleNavbarMethod();
        
        // bind resize event
        $(window).resize(toggleNavbarMethod);*/
    
    $('#btn-submit').click(function() {  
 
        $(".error").hide();
        var hasError = false;
        var emailReg = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
 
        var emailaddressVal = $("#UserEmail").val();
        if(emailaddressVal === '') {
            $("#newsletterForm").after('<p class="error">Please enter your email address.</p>');
            hasError = true;
        }
 
        else if(!emailReg.test(emailaddressVal)) {
            $("#newsletterForm").after('<p class="error">Enter a valid email address.</p>');
            hasError = true;
        }
        if(hasError === true) { return false; }

        if(hasError === false) {  
          $('#newsletterModal').modal('show');
          var emailValue = $("#UserEmail").val();
          console.log(emailValue);
          var modal = $("#newsletterModal");
  modal.find('.modal-body input#mce-EMAIL').val(emailValue);
          return false;}
 
    });

    $('#btn-submit-contact').click(function() {  
 
        $(".errorcontact").hide();
        var hasError = false;
        var emailReg = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
 
        var emailaddressVal = $("#UserEmailContact").val();
        if(emailaddressVal === '') {
            $("#newsletterFormContact").after('<p class="errorcontact">Please enter your email address.</p>');
            hasError = true;
        }
 
        else if(!emailReg.test(emailaddressVal)) {
            $("#newsletterFormContact").after('<p class="errorcontact">Enter a valid email address.</p>');
            hasError = true;
        }
        if(hasError === true) { return false; }

        if(hasError === false) {  
          $('#newsletterModal').modal('show');
          var emailValue = $("#UserEmailContact").val();
          console.log(emailValue);
          var modal = $("#newsletterModal");
  modal.find('.modal-body input#mce-EMAIL').val(emailValue);
          return false;}
 
    });
});

        // JavaScript to be fired on all pages
           $('ul.qtrans_language_chooser li:not(.active)').hide();
   
          var activeLi = $('ul.qtrans_language_chooser li.active');
        $('ul.qtrans_language_chooser').prepend(activeLi);
   
        $('ul.qtrans_language_chooser li').mouseover(function(){
          $(this).parent('ul').children('li').stop().show();
        });
   
        $('ul.qtrans_language_chooser').mouseout(function(){
          $(this).children('li:not(.active)').stop().hide();
        });
        
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'interviews': {
       init: function() {
        // JavaScript to be fired on the home page
                var $container = $('.masonry-container');
        $container.imagesLoaded( function () {
        $container.masonry({
          columnWidth: '.col-sm-4',
          itemSelector: '.item',
          percentPosition: true
        });   
      });
      },
      finalize: function() {
        // JavaScript to be fired on the about us page

      }
    },
        'single_participant': {
       init: function() {
        // JavaScript to be fired on the home page
$(window).load(function () {
  var container = $('.mentorReviewTextDescription .wp-caption');
    var image = $('.mentorReviewTextDescription .wp-caption .iwmp-single img');
    image.each(function () {
        var that = $(this);
        if (that.width() < 616) {
            that.parent().parent().addClass('work-text-small');
        }
    });

});
      },
      finalize: function() {
        // JavaScript to be fired on the about us page

      }
    },
    // About us page, note the change from about-us to about_us.
    'participants': {
       init: function() {
        // JavaScript to be fired on the home page
                var $container = $('.masonry-container');
        $container.imagesLoaded( function () {
        $container.masonry({
          columnWidth: '.col-sm-4',
          itemSelector: '.item',
          percentPosition: true
        });   
      });
      },
      finalize: function() {
        // JavaScript to be fired on the about us page

      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
